import { useEffect } from "react";
import "./footer.scss";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import WhatsApp from "../../assets/images/whatsapp-icon.png";
const Footer = () => {
  useEffect(() => {
    let counterContainer = document.querySelector(".website-counter");
    let visitCount = localStorage.getItem("page_view");
    visitCount = 65;

    //Add entry for key="page_view"
    localStorage.setItem("page_view", 1);
    visitCount = Number(visitCount) + 1;

    // Update local storage value
    localStorage.setItem("page_view", visitCount);
    counterContainer.innerHTML = visitCount;
    // console.log(visitCount, counterContainer);
  });
  const websiteVisits = (response) => {
    // console.log(response);
    // document.querySelector(".website-counter").textContent = response.value;
  };
  const phoneNumber = "+917397784467";
  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#25d366", // WhatsApp green color
    padding: "10px",
    borderRadius: "50%",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    transition: "background-color 0.3s ease-in-out",
  };
  const buttonStyle2 = {
    position: "fixed",
    
    bottom: "20px",
    top: "80%",
    right: "0",
    // transform:' translateY(-50%)',
    // transition: 'background-color 0.3s ease #000800',
    willchange: "transform",
    padding: "10px",
    height: "40px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
  };

  const imgStyle = {
    width: "30px", // Set the width of your WhatsApp icon
    height: "auto",
    borderRadius: "50%", // Make the image circular
  };

  return (
    <section id="contact" className="p-0">
      <Helmet></Helmet>
      <div className="container">
        <div className="divider">
          <span></span>
          <span>
            {" "}
            <h5>Contact us</h5>
          </span>
          <span></span>
        </div>
        {/* <div className="container"> */}
        <div className="row">
          <div className="space-25">&nbsp;</div>
          <div className="col-lg-2 col-md-6 col-xs-12 feature-left space-top">
            <div className="feature block">
              <div className="feature-icon col-md-12">
                <div className="col-md-12 h5 p-0 ">
                  <i className="fa fa-phone "></i>
                  &nbsp;&nbsp; Telephone
                </div>
              </div>
              <br />
              <div className="clearfix"></div>
              <div
                className="col-md-12 "
                // style="margin-top: 30px; padding:6px 0px;"
              >
                <a href="tel:+919361909163">+91 93619 09163</a>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+917305057759">+91 73050 57759</a>
              </div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+919566223456">+91 95662 23456</a>
              </div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+919361909071">+91 94433 73325</a>
              </div>
              <div className="col-md-12" style={{ padding: "6px 0px" }}>
                <a href="tel:+919360823456">+91 93608 23456</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-xs-12 feature-left space-top">
            <div className="feature block">
              <div className="feature-icon">
                <div className="col-md-12 h5">
                  <i className="fa fa-envelope"></i>&nbsp;&nbsp; Email
                </div>
              </div>
              <br />
              <div className="clearfix"></div>
              <div
                className="col-md-12"
                // style="margin-top: 30px; padding: 7px 0px;"
              >
                <a
                  href="mailto:svtrust.educ@gmail.com"
                  className="contact-info-text"
                >
                  svtrust.educ@gmail.com
                </a>
              </div>
              <div className="col-md-12" style={{ padding: "7px 0px" }}>
                <a
                  href="mailto:deanjsacat@tnau.ac.in"
                  className="contact-info-text"
                >
                  deanjsacat@tnau.ac.in
                </a>
              </div>
              <div className="col-md-12">
                <a href="jsacatoffice1@gmail.com" className="contact-info-text">
                  jsacatoffice2@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-xs-12 feature-left space-top">
            <div className="feature block">
              <div className="feature-icon">
                <div className="col-md-12 h5">
                  <i className="fa fa-home"></i>&nbsp;&nbsp; Reach us
                </div>
              </div>
              <br />
              <div className="clearfix"></div>
              <div
                className="col-md-12"
                // style="margin-top: 30px; padding: 0px;"
              >
                <p>
                  JSA College of Agriculture and Technology,
                  <br /> Avatti, Tholudur, Cuddalore (District),
                  <br /> Tamil Nadu &ndash; 606 108.
                </p>
              </div>
            </div>
          </div>

          <div className=" col-lg-3 offset-lg-1  col-md-6 col-xs-12 feature-left space-top">
            <div className="feature block">
              <div className="col-md-12 h5">
                {" "}
                <i className="fa fa-map-marker"></i>&nbsp;&nbsp; Connect with Us
              </div>
              <br />

              <span className="social">
                <div className="clearfix"></div>
                <div className=" dropdown mb-2 jsa-groups">
                  <div
                    role="button"
                    data-bs-toggle="dropdown"
                    className="dropdown-toggle header"
                  >
                    {" "}
                    JSA GROUP OF INSTITUTIONS
                  </div>
                  <ul className="dropdown-menu ">
                    <li>
                      <a
                        href="https://jsasiddha.in"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dropdown-item"
                      >
                        JSA MEDICAL COLLEGE FOR SIDDHA AND RESEARCH CENTRE
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jncw.in"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dropdown-item"
                      >
                        JAWAHARLAL NEHRU COLLEGE FOR WOMEN
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <------- social media link --------> */}
                <div className="clearfix"></div>
                <div className="col-md-12 footer-social">
                  {" "}
                  <a
                    href="https://www.facebook.com/JSACAT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <i className="fa fa-facebook">&nbsp;&nbsp;Facebook </i>
                  </a>{" "}
                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 footer-social text-primary">
                  <a
                    href="https://www.instagram.com/jsa_college_of_agriculture/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <i className="fa fa-instagram text-warning">
                      &nbsp;&nbsp;Instagram
                    </i>
                  </a>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 footer-social">
                  <a
                    href="https://www.youtube.com/channel/UCiPczqmoazebpJg5hFif37A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube text-danger">
                      &nbsp;&nbsp;Youtube
                    </i>
                  </a>
                </div>
                {/* <div className="col-md-12 footer-social" >
                  <Link
                   to={`https://wa.me/${phoneNumber}`} target="_blank" className="btn btn-outline-warning"
                   type="button" style={buttonStyle2} >Latest News
                   </Link>
                      </div>      */}
                <div className="col-md-12 footer-social">
                  <Link
                    to={`https://wa.me/${phoneNumber}`}
                    target="_blank"
                    className="whatsapp-button"
                    type="button"
                    style={buttonStyle}
                    title=" Chat with us on WhatsApp Click Here"
                  >
                    <img src={WhatsApp} alt="WhatsApp" style={imgStyle} />
                  </Link>
                </div>
                <div className="clearfix"></div>
              </span>
            </div>
          </div>
        </div>

        <div className="clearfix"></div>
        {/* </div> */}
        <div className="clearfix"></div>
        <div className="container">
          <div className="container visit_counter d-flex justify-content-end">
            <button className="btn btn-secondary fw-bold">
              Visitors : <span className="website-counter"></span>
            </button>
          </div>
        </div>
        <div className="container">
          <p
            className="footer-info text-center p-0 "
            style={{ fontSize: "12px" }}
          >
            © CopyRights All Rights Reserved 2025 - <strong>Designed by</strong>{" "}
            - Shri Venkateswara Trust
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import Modal from "./modal"; // Assuming you have a reusable Modal component
import Modalbanner from "../../assets/images/gallery/2025/feb/2nd gradutions day/Img- (11).jpeg";
import Modalbanner2 from "../../assets/images/admission/graduations -2.jpg";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo/JsaAgri-logo-1.png";
// import Rightmodal from  "../../assets/images/Modal image/Modal imag (2).jpeg";// For routing functionality
// import leftmodal from "../../assets/images/Modal image/Modal imag (1).jpeg";

const HomeWithImageModal = () => {
  // State management for modals
  const [isFirstModalOpen, setFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [isThirdModalOpen, setThirdModalOpen] = useState(false);
  const [isFourthModalOpen, setFourthModalOpen] = useState(false);
  const [isSideModalsOpen, setSideModalsOpen] = useState(false); // New state for side modals

  const location = useLocation(); // Get the current route's pathname

  // Open the first modal if on the Home page
  useEffect(() => {
    if (location.pathname === "/") {
      setFirstModalOpen(true);
    }
  }, [location.pathname]);

  // Handlers for closing each modal and opening the next
  const handleFirstModalClose = () => {
    setFirstModalOpen(false);
    setSecondModalOpen(true); // Open second modal
  };

  const handleSecondModalClose = () => {
    setSecondModalOpen(false);
    setThirdModalOpen(true); // Open third modal
  };

  const handleThirdModalClose = () => {
    setThirdModalOpen(false);
    setSideModalsOpen(true); // Open left and right side modals
  };
  const handleFourthModalClose = () => {
    setFourthModalOpen(false);
    setSideModalsOpen(true); // Open left and right side modals
  };

  const handleSideModalsClose = () => {
    setSideModalsOpen(false); // Close side modals
  };

  return (
    <div className="modal-screen">
      {/* First Modal */}
      <Modal isOpen={isFirstModalOpen} onClose={handleFirstModalClose}>
        <img src={Logo} style={{ width: "100px", height: "100px" }} />
        <h5>JSA COLLEGE OF AGRICULTURE AND TECHNOLOGY</h5>
               <h6>Admission Open 2025-2026</h6>
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSeZxcJeVBJrzsBiwXSC3POLhWl46Zx3gotAEiTtkEriulH8tA/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button type="button" className="btn btn-success">
            Admission Enquiry Click Here
          </button>
        </Link>
      </Modal>

      {/* Second Modal */}
      <Modal isOpen={isSecondModalOpen} onClose={handleSecondModalClose}>
      <h5>LATEST EVENT</h5>
        <img
          src={Modalbanner}
          alt="Second Modal Banner"
          style={{ width: "100%", height: "100%" }}
        />
         
         <h6>2nd Graduations Day</h6>
         <h6>17 Feburary 2025</h6>
      </Modal>

      {/* Third Modal */}
      {/* <Modal isOpen={isThirdModalOpen} onClose={handleThirdModalClose}>
      <h5>UPCOMING EVENT</h5>
        <img
          src={Modalbanner2}
          alt="Third Modal Banner"
          style={{ width: "100%", height: "auto" }}
        />
<h6>2nd Graduations Day</h6>
<h6>17 Feburary 2025</h6>
      </Modal> */}
      {/* <Modal isOpen={isFourthModalOpen} onClose={handleFourthModalClose}>
        <img
          src={Modalbanner2}
          alt="Fourth Modal Banner"
          style={{ width: "100%", height: "auto" }}
        />
        <h5></h5>
      </Modal> */}
    </div>
  );
};

export default HomeWithImageModal;
